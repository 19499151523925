import center from './assets/center.png';
import twitter_icon from "./assets/twitter_icon.png"
import telegram_icon from "./assets/telegram_icon.png"
import instagram_icon from "./assets/instagram_icon.png"
import reddit_icon from "./assets/reddit_icon.png"
import chart_icon from "./assets/chart_icon.png"
import meme_one from './assets/meme_one.png'
import meme_two from "./assets/meme_two.jpg"
import meme_three from "./assets/meme_three.jpg"
import meme_four from "./assets/meme_four.png"
import meme_five from './assets/meme_five.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <div className="header">
          <div className="socials">
                <a href="https://twitter.com/dugetoken"><img src={twitter_icon} className="social" alt="twitter icon" /></a>
                <a href="https://t.me/dugecommunitysol"><img src={telegram_icon} className="social" alt="telegram icon" /></a>
                <a href="https://www.instagram.com/dugetoken"><img src={instagram_icon} className="social" alt="instagram icon" /></a>
                <a href="https://www.reddit.com/r/dugetoken/"><img src={reddit_icon} className="social" alt="reddit icon" /></a>
                <a href="https://dexscreener.com/solana/7exfg9d6snhaldzegy6lok4fkrvgrxblelpq3ixotsk5"><img src={chart_icon} className="social" alt="chart icon" /></a>
          </div>
        </div>
      
      </header>

      <div className="center_content">
        <img src={center} className="center_content" alt="center_content" />
      </div>

      <div className="token_details">
        <div className="header">A SPESHAL KOYN FOOR SPESHAL PEEPOL</div>
        <div className="details">
          <div>1b supply</div>
          <div>LP burnt</div>
          <div>0/0 tax</div>
        </div>
      </div>

      <div className="memes_wrapper">
        <div className="header">DON BE A DUGEBAG. HODL UR DUGE BAG.</div>
        <div className="memes">
            <img src={meme_one} className="meme" alt="meme one" />
            <img src={meme_two} className="meme" alt="meme two" />
            <img src={meme_three} className="meme" alt="meme three" />
            <img src={meme_four} className="meme" alt="meme four" />
            <img src={meme_five} className="meme" alt="meme five" />
        </div>
      </div>

      <div className="token_address">Token Address: qSDh8UFuJ8sniXMpWSReeiY9jA8zLptKqCK7ZeYWumL</div>
    </div>
  );
}

export default App;
